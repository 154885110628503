import React from 'react'

function Quotes() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M11 17.67c0 1.154-.47 2.165-1.413 3.03-.942.867-2.052 1.3-3.333 1.3-2.011 0-3.557-.659-4.636-1.976C.54 18.707 0 16.884 0 14.557c0-2.165.928-4.349 2.784-6.55C4.64 5.806 6.876 4.137 9.491 3l1.207 1.922c-2.066.992-3.685 2.147-4.855 3.464-1.17 1.317-1.847 2.914-2.03 4.79h1.536c1.134 0 2.057.127 2.77.38.714.252 1.29.604 1.729 1.055.42.433.718.911.891 1.435.174.523.261 1.064.261 1.624zm13 0c0 1.154-.47 2.165-1.413 3.03-.942.867-2.052 1.3-3.333 1.3-2.011 0-3.557-.659-4.636-1.976C13.54 18.707 13 16.884 13 14.557c0-2.165.928-4.349 2.784-6.55C17.64 5.806 19.876 4.137 22.491 3l1.207 1.922c-2.066.992-3.685 2.147-4.855 3.464-1.17 1.317-1.847 2.914-2.03 4.79h1.536c1.134 0 2.057.127 2.77.38.714.252 1.29.604 1.729 1.055.42.433.718.911.891 1.435.174.523.261 1.064.261 1.624z" />
    </svg>
  )
  /* eslint-enable */
}

export default Quotes
