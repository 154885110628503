import React from 'react'
import classNames from 'classnames'
import styles from './icon.module.css'

function Icon(props) {
  const IconComponent = require(`../${props.icon}`).default

  return (
    <span
      className={classNames([styles.icon, props.className])}
      style={props.style}
    >
      <IconComponent fill={props.fill || 'currentColor'} />
    </span>
  )
}

export default Icon
