import React from 'react'

function Book() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M18 0h-2c-2.761 0-5 2.239-5 5v.025C10.14 4.364 9.085 4.003 8 4H2c-.552 0-1 .448-1 1v15c0 .552.448 1 1 1h6c1.363.007 2.55.93 2.89 2.25.114.441.512.75.967.75h.286c.455 0 .853-.309.967-.75.34-1.32 1.527-2.243 2.89-2.25h6c.552 0 1-.448 1-1V5c0-.552-.448-1-1-1h-3V1c0-.552-.448-1-1-1zm-5 5c0-1.657 1.343-3 3-3h1v13h-1c-1.085.003-2.14.364-3 1.025V5zM8 19H3V6h5c1.657 0 3 1.343 3 3v11.017c-.862-.658-1.916-1.015-3-1.017zM21 6v13h-5c-1.084.002-2.138.359-3 1.017V20c0-1.657 1.343-3 3-3h2c.552 0 1-.448 1-1V6h2z" />
    </svg>
  )
  /* eslint-enable */
}

export default Book
