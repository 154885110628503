import React, { Component } from 'react'
import styles from './sidebar.module.css'
import Button from '../button'
import Icon from '../icons/icon'

class Sidebar extends Component {
  state = {
    emailValue: '',
  }

  render() {
    return (
      <div className={styles.sidebar}>
        <a
          href="https://imaginationlibrary.com/check-availability/#selectedCountry=US"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>Register Your Child</Button>
        </a>
        <section className={styles.section}>
          <h3>Get Involved!</h3>
          <ul>
            <li>Tell your friends about this program.</li>
            <li>Share our registration page with a parent!</li>
            <li>
              Talk to your employer about a financial gift to Grand Junction
              Imagination Library!
            </li>
            <li>Contact us to volunteer!</li>
            <li>Host a fundraiser!</li>
          </ul>
        </section>
        <section className={styles.section}>
          <h3>Learn More!</h3>
          <div className={styles.linksColumn}>
            <a
              href="https://imaginationlibrary.com/about-us/"
              target="_blank"
              rel="noopener noreferrer"
            >
              About Dolly Parton’s Imagination Library
            </a>
            <a
              href="https://imaginationlibrary.com/news-resources/parent-resources/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resources For Parents
            </a>
            <a
              href="https://imaginationlibrary.com/news-resources/research/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Supportive Research
            </a>
          </div>
        </section>
      </div>
    )
  }
}

export default Sidebar
