import React from 'react'

function Mobile() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 2v4-4zm0 16v4-4zM4.93 4.93l2.83 2.83-2.83-2.83zm11.31 11.31l2.83 2.83-2.83-2.83zM2 12h4-4zm16 0h4-4zM4.93 19.07l2.83-2.83-2.83 2.83zM16.24 7.76l2.83-2.83-2.83 2.83z"
        stroke="#9CA4AF"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
  /* eslint-enable */
}

export default Mobile
