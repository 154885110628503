import React from 'react'

function Instagram() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 2.162c3.204 0 3.584.012 4.849.07 1.17.054 1.805.249 2.228.413.56.218.96.478 1.38.898.42.42.68.82.898 1.38.164.423.36 1.058.413 2.228.058 1.265.07 1.645.07 4.849s-.012 3.584-.07 4.849c-.054 1.17-.249 1.805-.413 2.228-.218.56-.478.96-.898 1.38-.42.42-.82.68-1.38.898-.423.164-1.058.36-2.228.413-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07c-1.17-.053-1.805-.249-2.228-.413-.56-.218-.96-.478-1.38-.898-.42-.42-.68-.82-.898-1.38-.164-.423-.36-1.058-.413-2.228-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849c.053-1.17.249-1.805.413-2.228.218-.56.478-.96.898-1.38.42-.42.82-.68 1.38-.898.423-.164 1.058-.36 2.228-.413 1.265-.058 1.645-.07 4.849-.07zM12 0C8.74 0 8.332.014 7.052.072 5.775.131 4.902.333 4.14.63c-.789.307-1.458.717-2.125 1.384C1.347 2.681.937 3.35.63 4.14c-.297.763-.5 1.635-.558 2.912C.014 8.332 0 8.741 0 12c0 3.26.014 3.668.072 4.948.058 1.277.261 2.15.558 2.913.307.789.717 1.458 1.384 2.125.667.667 1.336 1.077 2.125 1.384.764.297 1.636.5 2.913.558C8.332 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 1.277-.058 2.15-.261 2.912-.558.79-.307 1.459-.717 2.126-1.384.667-.667 1.077-1.336 1.384-2.125.297-.764.5-1.636.558-2.913.058-1.28.072-1.689.072-4.948 0-3.259-.014-3.668-.072-4.948-.059-1.277-.261-2.15-.558-2.912-.307-.79-.717-1.459-1.384-2.126C21.319 1.347 20.65.937 19.86.63c-.763-.297-1.635-.5-2.912-.558C15.668.014 15.259 0 12 0zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162S8.597 18.162 12 18.162s6.162-2.759 6.162-6.162S15.403 5.838 12 5.838zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.406c0 .796-.645 1.44-1.44 1.44-.796 0-1.44-.644-1.44-1.44 0-.795.644-1.44 1.44-1.44.795 0 1.44.645 1.44 1.44z"
        fill="#0A0A08"
        fillRule="nonzero"
      />
    </svg>
  )
  /* eslint-enable */
}

export default Instagram
