import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Nav from '../nav/'
import styles from './layout.module.css'
import Footer from '../footer/'
import Image from '../../static/gjillogo.png'

import '../../styles/reset.css'
import '../../styles/global.css'

const Layout = ({ children, data, callout }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'link',
              content:
                '<link rel="stylesheet" href="https://use.typekit.net/bus5hsu.css">',
            },
            {
              name: 'description',
              content:
                'Promoting the love of early reading and learning as the building blocks for a strong foundation in education, the program believes in helping children dream more, learn more, care more and be more through its educational campaign.',
            },
            {
              name: 'keywords',
              content: 'Library, Imagination, Grand Junction',
            },
            {
              name: 'viewport',
              content:
                'width=device-width,initial-scale = 1.0,maximum-scale = 1.0',
            },
            {
              name: 'image',
              content: Image,
            },
            {
              name: 'og:image',
              content: Image,
            },
            <meta
              property="og:image"
              content="http://euro-travel-example.com/thumbnail.jpg"
            />,
          ]}
        >
          <link rel="stylesheet" href="https://use.typekit.net/bus5hsu.css" />
        </Helmet>
        <Nav />
        <div className={styles.layout}>{children}</div>
        {callout && callout}
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  callout: PropTypes.node,
}

export default Layout
