import React, { Component } from 'react'
import { Link } from 'gatsby'
import styles from './nav.module.css'
import Logo from '../../static/gjillogo.png'
import Icon from '../icons/icon/'
import Button from '../button/'

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: styles.active } : null
}

class Navigation extends Component {
  state = {
    menuOpen: false,
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  renderNav() {
    return (
      <div className={styles.nav}>
        <Link to="/">
          <img src={Logo} alt="Logo" className={styles.navImage} />
        </Link>
        <div className={styles.navLinks}>
          <Link getProps={isPartiallyActive} to="/about">
            About
          </Link>
          <Link getProps={isPartiallyActive} to="/meet-the-team">
            Meet the Team
          </Link>
          <Link getProps={isPartiallyActive} to="/contact">
            Contact
          </Link>
          <Link to="/donate">
            <Button>Donate</Button>
          </Link>
        </div>
      </div>
    )
  }

  renderMobileNav() {
    return (
      <div className={styles.mobileNav}>
        <div className={styles.mobileNavHeader}>
          <Link to="/" className={styles.logoMark}>
            <img src={Logo} alt="Logo" className={styles.navImage} />
          </Link>
          <div className={styles.menuButton} onClick={() => this.toggleMenu()}>
            <Icon icon="Menu" />
          </div>
        </div>
        {this.state.menuOpen && (
          <div className={styles.mobileNavLinks}>
            <Link getProps={isPartiallyActive} to="/about">
              About
            </Link>
            <Link getProps={isPartiallyActive} to="/meet-the-team">
              Meet the Team
            </Link>
            <Link getProps={isPartiallyActive} to="/contact">
              Contact
            </Link>
          </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div className={styles.navWrapper}>
        {this.renderNav()}
        {this.renderMobileNav()}
      </div>
    )
  }
}

export default Navigation
