import React from 'react'

function Testing() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8 11l3 3L22 3" />
        <path d="M20 12v7c0 1.333-.667 2-2 2H4c-1.333 0-2-.667-2-2V5c0-.552.195-1.024.586-1.414C2.976 3.196 3.448 3 4 3h11" />
      </g>
    </svg>
  )
  /* eslint-enable */
}

export default Testing
