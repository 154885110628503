import React from 'react'

function Computer() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M4 3h16c1.333 0 2 .667 2 2v10c0 1.333-.667 2-2 2H4c-1.333 0-2-.667-2-2V5c0-1.333.667-2 2-2zm4 18h8-8zm4-4v4-4z"
        stroke="#9CA4AF"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
  /* eslint-enable */
}

export default Computer
