import React from 'react'

function Facebook() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M22.675 24c.732 0 1.325-.593 1.325-1.325V1.325C24 .593 23.407 0 22.675 0H1.325C.593 0 0 .593 0 1.325v21.35C0 23.407.593 24 1.325 24h21.35"
          fill="#395185"
        />
        <path
          d="M16.14 24v-9.123h3.243l.486-3.555h-3.73v-2.27c0-1.03.304-1.731 1.867-1.731L20 7.32V4.14c-.345-.043-1.529-.14-2.905-.14-2.875 0-4.844 1.657-4.844 4.7v2.622H9v3.555h3.251V24h3.889"
          fill="#FFF"
        />
      </g>
    </svg>
  )
  /* eslint-enable */
}

export default Facebook
