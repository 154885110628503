import React, { Component } from 'react'
import styles from './button.module.css'
import classNames from 'classnames'

class Button extends Component {
  render() {
    const { children, className, type } = this.props

    const buttonClasses = {
      [styles.button]: true,
      [className]: className,
    }

    return (
      <button type={type} className={classNames(buttonClasses)}>
        {children}
      </button>
    )
  }
}

export default Button
