import React, { Component } from 'react'
import styles from './footer.module.css'

class Footer extends Component {
  render() {
    const date = new Date();
    return (
      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <div className={styles.charity}>
            <span>CCC Charity ID Number: 2605</span>
            <span>CFC Charity ID Number: 85347</span>
          </div>
          <span>© {date.getFullYear()} Grand Junction Imagination Library</span>
        </div>
      </div>
    )
  }
}

export default Footer
