import React from 'react'

function Hexagon({ fill, className, style, size }) {
  /* eslint-disable max-len */
  return (
    <svg
      width={size || 24}
      height={size || 24}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      style={style}
      fill={fill || '#fff'}
    >
      <path d="M12 1l9.526 5.5v11L12 23l-9.526-5.5v-11z" />
    </svg>
  )
  /* eslint-enable */
}

export default Hexagon
