import React, { Component } from 'react'
import styles from './page-wrapper.module.css'
import Sidebar from '../sidebar'

class PageWrapper extends Component {
  render() {
    const { children } = this.props

    return (
      <div className={styles.pageWrapper}>
        <div className={styles.pageWrapperLeft}>{children}</div>
        <Sidebar />
      </div>
    )
  }
}

export default PageWrapper
