import React from 'react'

function Children() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M7 2.2c-1.656 0-3 1.343-3 3 0 1.656 1.344 3 3 3s3-1.344 3-3c0-1.657-1.344-3-3-3zm0 4c-.553 0-1-.448-1-1 0-.553.447-1 1-1 .553 0 1 .447 1 1 0 .552-.447 1-1 1zM4 10.2l-4 4 1.414 1.414L4 13.028V22.2h2v-4h2v4h2v-12H4zm4 6H6v-4h2v4zM24 14.2l-4-4h-6l-2 9h2v3h2v-3h2v3h2v-3h2l-1.193-5.363 1.779 1.777L24 14.2zm-9.507 3l1.111-5h2.792l1.11 5h-5.013zM17 2.2c-1.656 0-3 1.343-3 3 0 1.656 1.344 3 3 3s3-1.344 3-3c0-1.657-1.344-3-3-3zm0 4c-.553 0-1-.448-1-1 0-.553.447-1 1-1 .553 0 1 .447 1 1 0 .552-.447 1-1 1z" />
    </svg>
  )
  /* eslint-enable */
}

export default Children
